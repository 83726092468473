import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import NotificationImportantTwoToneIcon from '@mui/icons-material/NotificationImportantTwoTone';

import ApiService from './ApiServices';
import BuscadoryFiltros from './BuscadoryFiltros';


function createData(solicita, unidadAcademica, carrera, fechasolicitud, estado) {
  return { solicita, unidadAcademica, carrera, fechasolicitud, estado };
}

const initialRows = [
  createData('Litzy Nevarez', 'Facultad De Trabajo Social Exactas', 'Licenciado En Trabajo Social', '8/nov/2024, 1:28 pm', 'En espera'),
  createData('Juan Pérez', 'Facultad de Ingeniería', 'Ingeniería Civil', '9/nov/2024, 2:30 pm', 'Aprobado'),
  createData('Ana López', 'Facultad de Medicina', 'Medicina General', '10/nov/2024, 11:45 am', 'Rechazado'),
];

export default function EnProceso() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [rows, setRows] = useState(initialRows);
  const [selectedEstado, setSelectedEstado] = useState('');
  const [estados, setEstados] = useState([]);

  const [selectedTipo, setSelectedTipo] = useState('');
  const navigate = useNavigate();

  const getIconForState = (state) => {
    switch (state) {
      case 'Documentos rechazados':
        return <ErrorTwoToneIcon style={{ color: 'orange' }} />;
      case 'Documentos aceptados':
        return <ErrorTwoToneIcon style={{ color: 'orange' }} />;
      case 'Solicitud rechazada':
        return <CancelTwoToneIcon style={{ color: 'red' }} />;
      case 'Solicitud aprobada':
        return <CheckCircleTwoToneIcon style={{ color: 'green' }} />;
      case 'Nuevo':
        return <NotificationImportantTwoToneIcon style={{ color: '#d6c000' }} />;
      default:
        return null;
    }
  };

  // Información para la tabla
  const columns = [
    { id: 'matricula', label: <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Matrícula</span>, minWidth: 170 },
    { id: 'nombre', label: <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Solicita</span>, minWidth: 170 },
    { id: 'fechasolicitud', label: <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Fecha de solicitud</span>, minWidth: 100 },
    { 
      id: 'estado', label: <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Estado</span>, minWidth: 100, 
      render: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {getIconForState(row.estado)}
          {row.estado}
        </div>
      ),
    },
    {
      id: 'acciones',
      label: '',
      minWidth: 100,
      render: (row) => (
        <Button
          variant="contained"
          style={{ backgroundColor: 'rgb(126, 173, 113)', color: 'white' }}
          onClick={() => handleAction(row)}
        >
          Ver
        </Button>
      ),
    },
  ];

  // Función para manejar la acción del botón
  const handleAction = (row) => {
    navigate(`/main/detalles/${row.id}`);
    //console.log('Detalles de la fila:', row.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [allRows, setAllRows] = useState(initialRows);
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);
  
    const filteredRows = allRows.filter((row) =>
      Object.values(row).some((val) =>
        val.toString().toLowerCase().includes(value)
      )
    );
  
    setRows(filteredRows);
  };

  // Función para manejar el cambio de estado y actualizar la tabla
  const handleEstadoChange = (event) => {
    const estadoId = event.target.value;
    setSelectedEstado(estadoId);

    if (estadoId) {
      ApiService.fetchProceduresByEstado(estadoId)
        .then(data => setRows(data))
        .catch(console.error);
    }
  };

  // Función para manejar el cambio del tipo a la tabla
  const handleChangeTipo = (event) => {
    const tipoSeleccionado = event.target.value;
    setSelectedTipo(tipoSeleccionado);

    ApiService.fetchProceduresByTipo(tipoSeleccionado)
      .then(data => setRows(data))
      .catch(console.error);
  };

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      try {
        const formattedData = await ApiService.fetchProcedures();
        if (isMounted) {
          setAllRows(formattedData);
          setRows(formattedData);
          //console.log('Datos recibidos:', formattedData);
        }
      } catch (error) {
        console.error('Error al procesar los datos:', error);
      }
    };
  
    //ApiService.fetchEstados().then(setEstados).catch(console.error);
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, []);

  const paginationTexts = {
    firstAriaLabel: 'Primera página',
    firstTooltip: 'Primera página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Siguiente página',
    nextTooltip: 'Siguiente página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página',
    labelRowsPerPage: 'Filas por página',
    displayedRows: '{from}-{to} de {count}',
  };

  return (
    <div>
      <Typography variant="h4" component="h2" style={{ marginBottom: '20px' }}>
        Lista de solicitudes
      </Typography>

      <BuscadoryFiltros
        searchValue={searchValue}
        handleSearch={handleSearch}
        estados={estados}
        selectedEstado={selectedEstado}
        handleEstadoChange={handleEstadoChange}
        selectedTipo={selectedTipo}
        handleChangeTipo={handleChangeTipo}
      />

      {/* Inicio de la tabla */}
      <div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          
          <div style={{padding: '1rem', textAlign: 'left', color: '#7e7e7a'}}>
            Se muestran {rows.length} solicitudes.
          </div>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                
                {rows
                  .sort((a, b) => (b.estado === 'Nuevo' ? 1 : 0 ) - (a.estado === 'Nuevo' ? 1 : 0))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        {console.log(row.estado)}
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.render
                              ? column.render(row)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página"
          />
        </Paper>
      </div>
    </div>
  );
}
